import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header/header"
import "font-awesome/css/font-awesome.css"
import "./layout.scss"
import "bootstrap/dist/css/bootstrap.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      {/*
      <div className="footer">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-3 mb-5">
              <div className="text-center text-md-left">
                <img className="img-fluid" src={Logo} alt="Breathe Again" />
              </div>
            </div>
            <div className="col-md-2 mb-5">
              <div className="text-center text-md-left" x="">
                <h6 className="font-weight-bold text-uppercase">How We Help</h6>
                <div className="text-center text-md-left">
                  <ul className="footer-links">
                    <li>
                      <Link to="/">Service 1</Link>
                    </li>
                    <li>
                      <Link to="/">Service 2</Link>
                    </li>
                    <li>
                      <Link to="/">Service 3</Link>
                    </li>
                    <li>
                      <Link to="/">Service 4</Link>
                    </li>
                    <li>
                      <Link to="/">Service 5</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-5">
              <div>
                <h6 className="font-weight-bold text-uppercase">
                  Latest Posts
                </h6>
                <div className="row blog-item">
                  <div className="col-4 pr-0">
                    <Link to="/">
                      <div
                        className="bg"
                        style={{
                          backgroundImage: `url(${Blog1})`,
                        }}
                      />
                    </Link>
                  </div>
                  <div className="col-8">
                    <h6 className="m-0 font-weight-bold">
                      <Link to="/">This is a sample blog post</Link>
                    </h6>
                    <p className="small text-muted truncate-2 m-0">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam.
                    </p>
                  </div>
                </div>
                <div className="row blog-item">
                  <div className="col-4 pr-0">
                    <Link to="/">
                      <div
                        className="bg"
                        style={{
                          backgroundImage: `url(${Blog2})`,
                        }}
                      />
                    </Link>
                  </div>
                  <div className="col-8">
                    <h6 className="m-0 font-weight-bold">
                      <Link to="/">Another post</Link>
                    </h6>
                    <p className="small text-muted truncate-2 m-0">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-5">
              <div>
                <h6 className="font-weight-bold text-uppercase">
                  Subscribe to our newsletter
                </h6>
                <Form
                  form_name="newsletter"
                  form_id="5dd5f94528afbd0cd50c2a22"
                  method="post"
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="email"
                      required=""
                      name="email_address"
                      id="email_address"
                    />
                    <label htmlFor="email_address">Email address</label>
                  </div>
                  <div className="form-group mb-0">
                    <div className="webriq-recaptcha" />
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-primary btn-block btn-sm"
                      type="submit"
                    >
                      Subscribe
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>*/}
      <div className="copyright-section bg-dark text-white-50 py-3 small">
        <div className="container">
          <ul className="list-unstyled d-md-flex justify-content-md-between m-0">
            <li>
              © 2019 Breathe Again Bereavement Doula, LLC. All rights reserved.
            </li>
            <li>
              Designed and powered by{" "}
              <a
                className="text-white-50"
                href="https://webriq.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                WebriQ
              </a>
              .
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
