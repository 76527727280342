import React from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import "./menu.css"

export default () => (
	<div className="navigation">
		<Nav className="flex-column">
			<Nav.Item>
				<Link to="/">Home</Link>
			</Nav.Item>
			<Nav.Item>
				<Link to="/#my-story">My Story</Link>
			</Nav.Item>
			<Nav.Item>
				<Link to="/#services">Services</Link>
			</Nav.Item>
			<Nav.Item>
				<Link to="/#contact">Contact Us</Link>
			</Nav.Item>
			{/*<Dropdown as={NavItem}>
				<Dropdown.Toggle as={Nav.Link}>Dropdown</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item>Hello there!</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
			<Dropdown as={NavItem}>
				<Link variant="success" className="float-left" to="/">
					Dropdown Split Button
				</Link>
				<Dropdown.Toggle split id="dropdown-split-basic" />
				<Dropdown.Menu>
					<Dropdown.Item hred="#/action-1">Action</Dropdown.Item>
					<Dropdown.Item hred="#/action-2">Another action</Dropdown.Item>
					<Dropdown.Item hred="#/action-3">Something else</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>*/}
		</Nav>
		<div className="pt-4">
			<div className="d-inline-block">
				<a
					href="https://www.facebook.com/Breathe-Again-Bereavement-Doula-105347920808436/"
					target="_blank"
					rel="noreferrer noopener"
				>
					<div className="contact-icon facebook" />
				</a>
			</div>
			<div className="d-inline-block">
				<a
					href="https://www.linkedin.com/in/sierra-spencer-27904718b"
					target="_blank"
					rel="noreferrer noopener"
				>
					<div className="contact-icon linkedin" />
				</a>
			</div>
		</div>
	</div>
)
