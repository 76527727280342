import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThankYouPage = () => (
	<Layout>
		<SEO title="Thank you!" />
		<div
			className="bg-light pt-5 text-center"
			style={{ height: "80vh", position: "relative" }}
		>
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "0",
					right: "0",
					transform: "perspective(1px) translateY(-50%)",
				}}
			>
				<div className="container">
					<h1>Thank you!</h1>
					<p>We received your message and will get back to you shortly.</p>
					<Link className="btn btn-primary" role="button" to="/">
						Take Me Home
					</Link>
				</div>
			</div>
		</div>
	</Layout>
)

export default ThankYouPage
