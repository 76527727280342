import React from "react"
import { Link } from "gatsby"
import Sticky from "react-sticky-el"
import SideBarMenu from "../sidebar/sidebar"
import "./header.css"

import Logo from "../../images/logo.png"

const Header = () => (
  <header>
    <Sticky
      className="sticky-wrapper"
      stickyClassName="is-sticky"
      stickyStyle={{ transform: "unset", zIndex: "2" }}
    >
      <div className="header">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-4 text-left d-none d-md-inline-block">
              <div className="contact-icon phone">
                <span className="d-block font-serif font-italic">
                  Talk to Me
                </span>
                <a
                  className="display-6 font-weight-bold"
                  href="tel:1-719-460-9991"
                >
                  719-460-9991
                </a>
              </div>
            </div>
            <div className="col-7 col-md-4 text-center">
              <Link to="/">
                <img
                  className="img-fluid logo"
                  src={Logo}
                  alt="Breathe Again"
                />
              </Link>
            </div>
            <div className="col-5 col-md-4 text-right">
              <SideBarMenu />
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </header>
)

export default Header
